import Grid from '@material-ui/core/Grid';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useContext, useState } from 'react';
import { Helmet } from 'react-helmet';

import AccountLayout from '../../components/account-layout';
import Alerts from '../../components/alerts';
import Backdrop from '../../components/backdrop';
import { useTranslation } from '../../hooks/useTranslation';
import colors from '../../themes/main-colors';
import MultiAccountSummary from '../../components/account-summary/multi-accounts/MultiAccountSummary';
import AccountDropdown from '../../components/account-dropdown';
import SingleAccountSummary from '../../components/account-summary/single-account/SingleAccountSummary';
import TextLink from '../../components/text-link';
import ROUTES from '../../routes';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import useAccountCustomer from '../../hooks/useAccountCustomer';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';
import {
  removeFromStorage,
  setInStorage,
  useStoredState,
} from '../../util/storage-utils';
import { useLocation } from '@reach/router';
import { getSelectedAccount } from '../../components/account-dropdown/queries';
import useAuthQuery from '../../hooks/useAuthQuery';
import { featureFlagsContext } from '../../providers/FeatureFlagsProvider';
import { NotificationsContext } from '../../providers/NotificationsProvider';
import ReconnectServiceSuccessNotice from '../../components/reconnect-service/reconnect-service-success-notice';
import useAccountsStore from '../../hooks/useAccountsStore';
import { Link } from 'gatsby';
import { PublicSafetyPowerShutoffDetails } from '../../components/public-safety-power-shutoff/PublicSafetyPowerShutoffDetails';
import AlertBanner from '../../components/AlertBanner';

export const SELECTED_ACCOUNT_STORAGE_KEY =
  'selected-account-from-summary-page';
const FORCE_MULTI_KEY = 'forceShowMultiSummary';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainArea: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    section: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    backLink: {
      '& a': {
        display: 'flex',
        alignItems: 'center',
        '&:active': {
          borderBottom: 'none',
        },
      },
      marginBottom: theme.spacing(1),
    },
  }),
);

type Props = {
  path?: string;
};

type LocationState = {
  showTwoStepAuthInfoBanner: boolean;
};

export default function AccountSummaryPage(_: Props) {
  const {
    accountParams,
    encryptedAccountNumber,
    encryptedPersonId,
    automaticSelection,
    selectedGroupId,
    setSelectedAccountParams,
  } = useSelectedAccountParams();
  const location = useLocation();

  const { t, richT } = useTranslation();
  const classes = useStyles();
  const { customer, error, loading } = useAccountCustomer();
  const [isAccountDisonnected, setIsAccountDisonnected] = useState<boolean>(
    false,
  );
  const { customerAccountState } = useAccountsStore();
  const [
    isAccountEligibleForReconnect,
    setIsAccountEligibleForReconnect,
  ] = useState<boolean>(false);
  const [isReconnectInProgress, setIsReconnectInProgress] = useState<boolean>(
    false,
  );
  const [forceShowMulti, setForceShowMulti] = useStoredState(
    FORCE_MULTI_KEY,
    false,
  );
  const totalAccounts = customer?.accountMeta?.totalAccounts || 0;
  const hasInactiveAccounts = customerAccountState.hasInactiveAccounts;
  const notificationContext = useContext(NotificationsContext);
  const { displayMyAccountInfoBanner } = useContext(featureFlagsContext);
  const { isPspsActivated } = useContext(featureFlagsContext);
  const { data } = useAuthQuery(getSelectedAccount, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    skip: !accountParams || loading,
  });

  const locationState = (location.state || {}) as LocationState;

  const { OMSdowntime } = useContext(featureFlagsContext);

  //handle energy use menu link for multiple account
  //if the url has hash of #energyUser we modify the forceShowMulti variable
  useEffect(() => {
    if (location.hash === '#energyUse') {
      const selectedAccount = data?.getAccountDetails?.[0];
      selectedAccount &&
      selectedGroupId &&
      totalAccounts && // check if total  account value is not zero
      totalAccounts > 1 && // set selected account only for multi user with multi account
        setSelectedAccountParams(selectedGroupId, selectedAccount); // who selected account value is not set
      setForceShowMulti(false); // by setting flag to false we redirect user to single summary page
    }
  }, [location, data]);

  useEffect(() => {
    //Show a two step authentication info banner for users who have not enrolled into MFA
    if (locationState?.showTwoStepAuthInfoBanner) {
      notificationContext.setState({
        isOpen: true,
        message: (
          <Typography component="p">
            {t('ENABLE_TWO_STEP_AUTH_FOR_ACCOUNT_1')}
            <Link to={ROUTES.TWO_STEP_AUTHENTICATION}>
              {t('TWO_STEP_AUTHENTICATION')}
            </Link>
            {t('ENABLE_TWO_STEP_AUTH_FOR_ACCOUNT_2')}
          </Typography>
        ),
        severity: 'info',
      });
    }
  }, [locationState]);

  useEffect(() => {
    if (displayMyAccountInfoBanner) {
      notificationContext.setState({
        isOpen: true,
        message: t('MYACCOUNT_INFO_BANNER'),
        severity: 'info',
      });
    }
  }, [displayMyAccountInfoBanner]);

  const showSingle =
    (totalAccounts <= 1 || !automaticSelection) && !forceShowMulti;

  useEffect(() => {
    if (!loading && !showSingle) {
      removeFromStorage(SELECTED_ACCOUNT_STORAGE_KEY);
    } else if (accountParams?.accountNumber) {
      setInStorage(SELECTED_ACCOUNT_STORAGE_KEY, accountParams?.accountNumber);
    }
  }, [loading, showSingle, accountParams]);

  if (!loading && !showSingle) {
    return (
      <>
        <MultiAccountSummary
          onSelectAccount={() => {
            setForceShowMulti(false);
          }}
        />
      </>
    );
  }

  return (
    <>
      {isReconnectInProgress && isAccountDisonnected && (
        <ReconnectServiceSuccessNotice />
      )}

      <AccountLayout dense={false} showSingle={showSingle}>
        <Helmet>
          <title>{t('ACCOUNT_SUMMARY')}</title>
        </Helmet>

        <div className={classes.mainArea}>
          {error !== undefined && (
            <Alerts
              isOpen
              severity={'error'}
              variant={'standard'}
              title={t('ACCOUNT_SUMMARY_ERROR')}
              message={error.toString()}
            />
          )}

          {loading && <Backdrop forceOpen />}

          <Grid container direction="column" spacing={3} wrap="nowrap">
            {!loading && (
              <>
                <Grid item>
                  {totalAccounts > 1 && (
                    <Typography
                      className={`${classes.backLink} ${classes.colorTextPrimary}`}
                      variant={'h4'}
                    >
                      <TextLink
                        onClick={async e => {
                          e.preventDefault();
                          setForceShowMulti(true);
                          window.scrollTo(0, 0);
                        }}
                        to={ROUTES.ACCOUNT}
                      >
                        <ChevronLeft />
                        {t('BACK_TO_ACCOUNT_LIST')}
                      </TextLink>
                    </Typography>
                  )}
                  <Typography
                    className={classes.colorTextPrimary}
                    variant={'h1'}
                  >
                    {t('MY_ACCOUNT_DASHBOARD')}
                  </Typography>
                </Grid>

                <Grid item md={12}>
                  {showSingle && <AccountDropdown />}
                </Grid>
              </>
            )}

            {!loading && isPspsActivated && showSingle && accountParams && (
              <PublicSafetyPowerShutoffDetails
                isAccountSelected={showSingle}
                accountParams={[accountParams]}
              />
            )}

            {OMSdowntime && (
              <Grid item>
                <AlertBanner
                  icon={false}
                  title={t('OMS_DOWN_TIME_TITLE')}
                  text={richT('OMS_DOWN_TIME_CUSTOMER_DASHBOARD')}
                />
              </Grid>
            )}

            {!loading && showSingle && (
              <SingleAccountSummary
                accountParams={accountParams}
                encryptedAccountNumber={encryptedAccountNumber}
                encryptedPersonId={encryptedPersonId}
                setIsDisonnected={setIsAccountDisonnected}
                setIsEligibleForReconnect={setIsAccountEligibleForReconnect}
                setIsReconnectInProgress={setIsReconnectInProgress}
              />
            )}
          </Grid>
        </div>
      </AccountLayout>
    </>
  );
}
